<template>
    <section id="content">
        <div class="content-wrap pt-0">
            <div class="section bg-transparent mt-4 mb-0 pb-0">
                <div class="container">
                    <div class="heading-block border-bottom-0 center mx-auto mb-0" style="max-width: 550px">
                        <div class="badge badge-pill badge-default">Services</div>
                        <h3 class="nott ls0 mb-3">What We Do</h3>
                        <p>We provide comprehensive services to build digital solutions & manage complete product lifecycle.</p>
                    </div>
                    <div class="row justify-content-between align-items-center">

                        <div class="col-lg-4 col-sm-6">

                            <div class="feature-box flex-md-row-reverse text-md-right border-0">
                                <div class="fbox-icon">
                                    <a href="#"><img src="../../assets/demos/seo/images/icons/seo.svg" alt="Feature Icon" class="bg-transparent rounded-0"></a>
                                </div>
                                <div class="fbox-content">
                                    <h3 class="nott ls0">Testing</h3>
                                    <p>We help You to make Your applications function without errors, in the most efficient way!</p>
                                </div>
                            </div>

                            <div class="feature-box flex-md-row-reverse text-md-right border-0 mt-5">
                                <div class="fbox-icon">
                                    <a href="#"><img src="../../assets/demos/seo/images/icons/adword.svg" alt="Feature Icon" class="bg-transparent rounded-0"></a>
                                </div>
                                <div class="fbox-content">
                                    <h3 class="nott ls0">UI/UX</h3>
                                    <p>We develop only those designs which are understandable and convenient to apply for the user.</p>
                                </div>
                            </div>

                            <div class="feature-box flex-md-row-reverse text-md-right border-0 mt-5">
                                <div class="fbox-icon">
                                    <a href="#"><img src="../../assets/demos/seo/images/icons/analysis.svg" alt="Feature Icon" class="bg-transparent rounded-0"></a>
                                </div>
                                <div class="fbox-content">
                                    <h3 class="nott ls0">Application development</h3>
                                    <p>Our development team will create an application for you using the latest technologies in the field of information technology and development that will provide the expected result for the business.</p>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-3 col-7 offset-3 offset-sm-0 d-sm-none d-lg-block center my-5">
                            <img  style="width: 100%;height: auto;" src="../../assets/demos/seo/images/sections/2.png" alt="iphone" class="rounded  parallax" data-bottom-top="transform: translateY(-30px)" data-top-bottom="transform: translateY(30px)">
                        </div>

                        <div class="col-lg-4 col-sm-6">

                            <div class="feature-box border-0">
                                <div class="fbox-icon">
                                    <a href="#"><img src="../../assets/demos/seo/images/icons/social.svg" alt="Feature Icon" class="bg-transparent rounded-0"></a>
                                </div>
                                <div class="fbox-content">
                                    <h3 class="nott ls0">Back-End implementation and Integration</h3>
                                    <p>For the smooth deployment and operation of the corporate applications, it is often necessary to work seamlessly with other Saas and local applications used for the various purposes.</p>
                                </div>
                            </div>

                            <div class="feature-box border-0 mt-5">
                                <div class="fbox-icon">
                                    <a href="#"><img src="../../assets/demos/seo/images/icons/experience.svg" alt="Feature Icon" class="bg-transparent rounded-0"></a>
                                </div>
                                <div class="fbox-content">
                                    <h3 class="nott ls0">Mobile Applications Analytics</h3>
                                    <p>To determine the indicators of the application efficiency, the user interaction, the revenue matrices and the redirection, we develop the specific analytical solutions for mobile applications.</p>
                                </div>
                            </div>

                            <div class="feature-box border-0 mt-5">
                                <div class="fbox-icon">
                                    <a href="#"><img src="../../assets/demos/seo/images/icons/content_marketing.svg" alt="Feature Icon" class="bg-transparent rounded-0"></a>
                                </div>
                                <div class="fbox-content">
                                    <h3 class="nott ls0">Content Marketing</h3>
                                    <p>With the time, previously created applications become obsolete and terminate to function properly. .</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <!-- Works/Projects
            ============================================= -->
            <div class="section our-apps m-0" style="">
                <div class="container">
                    <div class="heading-block border-bottom-0 center">
                        <div class="badge badge-pill badge-default">Completed Projects</div>
                        <h3 class="nott ls0">Our Latest Works</h3>
                    </div>

                    <div id="portfolio" class="portfolio row grid-container gutter-20">

                        <article class="portfolio-item col-12 col-sm-6 col-md-4 pf-media pf-icons">
                            <div class="grid-inner">
                                <div class="portfolio-image">
                                    <img  style="width: 100%;height: auto;" src="../../assets/portfolio/icon-1.png" alt="My Maps for Minecraft">
                                    <div class="bg-overlay">
                                        <div class="bg-overlay-content dark" data-hover-animate="fadeIn" data-hover-speed="500">
                                            <a href="/apps" class="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeIn" data-hover-speed="500"><i class="icon-line-ellipsis"></i></a>
                                        </div>
                                        <div class="bg-overlay-bg dark" data-hover-animate="fadeIn" data-hover-speed="500"></div>
                                    </div>
                                </div>
                                <div class="portfolio-desc">
                                    <h3><a href="/apps">Voice Changer - Recorder</a></h3>
                                    <span>iOS App</span>
                                </div>
                            </div>
                        </article>
                        <article class="portfolio-item col-12 col-sm-6 col-md-4 pf-media pf-icons">
                            <div class="grid-inner">
                                <div class="portfolio-image">
                                    <img  style="width: 100%;height: auto;" src="../../assets/portfolio/icon-2.png" alt="One Tap">
                                    <div class="bg-overlay">
                                        <div class="bg-overlay-content dark" data-hover-animate="fadeIn" data-hover-speed="500">
                                            <a href="/apps" class="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeIn" data-hover-speed="500"><i class="icon-line-ellipsis"></i></a>
                                        </div>
                                        <div class="bg-overlay-bg dark" data-hover-animate="fadeIn" data-hover-speed="500"></div>
                                    </div>
                                </div>
                                <div class="portfolio-desc">
                                    <h3><a href="/apps">VPN by Crafty</a></h3>
                                    <span>iOS App</span>
                                </div>
                            </div>
                        </article>
                        <article class="portfolio-item col-12 col-sm-6 col-md-4 pf-media pf-icons">
                            <div class="grid-inner">
                                <div class="portfolio-image">
                                    <img style="width: 100%;height: auto;" src="../../assets/portfolio/icon-4.png" alt="Coloring Book">
                                    <div class="bg-overlay">
                                        <div class="bg-overlay-content dark" data-hover-animate="fadeIn" data-hover-speed="500">
                                            <a href="/apps" class="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeIn" data-hover-speed="500"><i class="icon-line-ellipsis"></i></a>
                                        </div>
                                        <div class="bg-overlay-bg dark" data-hover-animate="fadeIn" data-hover-speed="500"></div>
                                    </div>
                                </div>
                                <div class="portfolio-desc">
                                    <h3><a href="/apps">Screen Recorder - Crafty</a></h3>
                                    <span>iOS App</span>
                                </div>
                            </div>
                        </article>

                    </div>

                    <div class="center">
                        <a href="/apps" class="button button-large button-rounded text-capitalize ml-0 mt-5 ls0">View All Works</a>
                    </div>

                </div>
            </div>


            <!-- Testimonials
            ============================================= -->
            <div class="section mt-0 reviews" >
                <div class="container">
                    <div class="heading-block border-bottom-0 center">
                        <div class="badge badge-pill badge-default">Testimonials</div>
                        <h3 class="nott ls0">What Clients Says</h3>
                    </div>

                    <div id="oc-testi" class="owl-carousel testimonials-carousel carousel-widget clearfix" data-margin="0" data-pagi="true" data-loop="true" data-center="true" data-autoplay="5000" data-items-sm="1" data-items-md="2" data-items-xl="3">

                        <div class="oc-item">
                            <div class="testimonial">
                                <div class="testi-image">
                                    <a href="#"><img src="../../assets/demos/pet/images/testimonials/1.jpg" alt="Customer Testimonails"></a>
                                </div>
                                <div class="testi-content">
                                    <p>Our work with the company Crafty Tech, SRL turned out to be very efficient in developing the mobile application for the iPhone, iPad and Android for our internal activity in the company. The guys showed full readiness to work in difficult conditions, tight deadlines, changing tasks, they showed the initiative! Well done!</p>
                                    <div class="testi-meta">
                                        John Doe
                                        <span>XYZ Inc.</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="oc-item">
                            <div class="testimonial">
                                <div class="testi-image">
                                    <a href="#"><img src="../../assets/demos/pet/images/testimonials/2.jpg" alt="Customer Testimonails"></a>
                                </div>
                                <div class="testi-content">
                                    <p>I contacted the company Crafty Tech, SRL with the problem of modification of the mobile application for the Android and IOS platform, as well as the implementation of the additional functionality. In the course of work, the options for optimizing the information exchange, the navigation improvement, and a new design were offered. The works were completed with a high quality and in time. We recommend the company as the mobile devices developer that know oats.</p>
                                    <div class="testi-meta">
                                        Collis Ta'eed
                                        <span>Envato Inc.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="oc-item">
                            <div class="testimonial">
                                <div class="testi-image">
                                    <a href="#"><img src="../../assets/demos/pet/images/testimonials/3.jpg" alt="Customer Testimonails"></a>
                                </div>
                                <div class="testi-content">
                                    <p>Within the process of automating the construction control of the technical supervision service, it became necessary to implement a document management system and develop a mobile application for it. The team Crafty Tech, SRL coped with that task successfully, its’ employees launched a solution based on a content management system and implemented a mobile application for the technical supervision engineers. There were embodied a few business-processes which allowed to improve the efficiency of the department and reduce the time spent on processing the documentation and the reporting preparation. We recommend the company for implementation of the complex information systems and the business automation. .</p>
                                    <div class="testi-meta">
                                        Collis Ta'eed
                                        <span>Envato Inc.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="oc-item">
                            <div class="testimonial">
                                <div class="testi-image">
                                    <a href="#"><img src="../../assets/demos/pet/images/testimonials/4.jpg" alt="Customer Testimonails"></a>
                                </div>
                                <div class="testi-content">
                                    <p>Within the process of automating the construction control of the technical supervision service, it became necessary to implement a document management system and develop a mobile application for it. The team Crafty Tech, SRL coped with that task successfully, its’ employees launched a solution based on a content management system and implemented a mobile application for the technical supervision engineers. There were embodied a few business-processes which allowed to improve the efficiency of the department and reduce the time spent on processing the documentation and the reporting preparation. We recommend the company for implementation of the complex information systems and the business automation.</p>
                                    <div class="testi-meta">
                                        Mary Jane
                                        <span>Google Inc.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="oc-item">
                            <div class="testimonial">
                                <div class="testi-image">
                                    <a href="#"><img src="../../assets/images/testimonials/5.jpg" alt="Customer Testimonails"></a>
                                </div>
                                <div class="testi-content">
                                    <p>We are very pleased with the work quality of the company Crafty Tech, SRL. The professionalism, the outgoingness and the understanding of developers allowed us to obtain stylish, functional and enjoyable to manage products. As a result of effective cooperation, we received really high-quality products with an optimal set of services for a real business dealing. We are sincerely grateful for the assistance and the helpful hints on completing sections on the sites, for the carefully studying of the work specifics of our company and for the distinct implementation in a cool design. It's nice to deal with the intelligent people and professionals!</p>
                                    <div class="testi-meta">
                                        Steve Jobs
                                        <span>Apple Inc.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Form Section
            ============================================= -->
           <ContactFragment/>
        </div>
    </section>
</template>

<script>

   // import ContactFragment from './../ContactFragment.vue'
    export default {
        name: "Home",
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Home',
            // all titles will be injected into this template
            titleTemplate: '%s | Crafty Tech'
        },
        components: {
           // ContactFragment,
            ContactFragment: () => import(/* webpackPrefetch: true */  './../ContactFragment.vue')

        },
    }
</script>

<style scoped>
    .our-apps {
        background: url('../../assets/demos/seo/images/sections/5.jpg') no-repeat center center;
        background-size: cover;
        padding: 80px 0;
    }
    .reviews {
        background: url('../../assets/demos/seo/images/sections/3.jpg') no-repeat top center;
        background-size: cover;
        padding: 80px 0 70px;
    }
    .testi-content p {
        text-align: center;
        font-style: normal;
        font-family: var(--fontfamily,'Poppins', sans-serif);
        font-size: var(--basefontsize, 16px);
        max-height: 151px;
        overflow: scroll;
    }

</style>